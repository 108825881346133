type CommandSets<T> = Record<keyof T, Set<string>>;
type AllCommands<T> = Set<T[keyof T] extends Array<infer X> ? X : never>;

class Commands<T extends Record<string, Array<string>>> {
  sets: CommandSets<T>;
  all: AllCommands<T>;

  constructor(sets: T) {
    this.sets = {} as CommandSets<T>;
    this.all = new Set();

    const entries = Object.entries(sets);

    for (const [key, values] of entries) {
      this.sets[key as keyof T] = new Set(values);
      for (const value of values) {
        this.all.add(value as any);
      }
    }
  }
}

export const commands = new Commands({
  home: ["home", "frontpage", "top", "index", "koti", "etusivu"] as const,
  about: ["about", "minusta", "minä", "who", "whoami", "santeri"] as const,
  projects: ["project", "projects", "projekti", "projektit"] as const,
  contact: ["contact", "email", "sähköposti", "yhteystiedot"] as const,
  english: ["en", "eng", "englanti", "english"] as const,
  finnish: ["fi", "fin", "finnish", "suomi"] as const,
  dark: ["dark", "tumma"] as const,
  light: ["light", "vaalea"] as const,
  pride: ["hlbt", "hlbtq", "lgbt", "lgbtq", "pride"] as const,
  hacker: ["hacker", "häkkeri"] as const,
  powershell: ["powershell", "windows"] as const,
  ball: ["ball", "pallo"] as const,
  wireframe: ["wireframe"] as const,
  cssColors: [
    "aliceblue",
    "antiquewhite",
    "aqua",
    "aquamarine",
    "azure",
    "beige",
    "bisque",
    "black",
    "blanchedalmond",
    "blue",
    "blueviolet",
    "brown",
    "burlywood",
    "cadetblue",
    "chartreuse",
    "chocolate",
    "coral",
    "cornflowerblue",
    "cornsilk",
    "crimson",
    "cyan",
    "darkblue",
    "darkcyan",
    "darkgoldenrod",
    "darkgray",
    "darkgreen",
    "darkgrey",
    "darkkhaki",
    "darkmagenta",
    "darkolivegreen",
    "darkorange",
    "darkorchid",
    "darkred",
    "darksalmon",
    "darkseagreen",
    "darkslateblue",
    "darkslategray",
    "darkslategrey",
    "darkturquoise",
    "darkviolet",
    "deeppink",
    "deepskyblue",
    "dimgray",
    "dimgrey",
    "dodgerblue",
    "firebrick",
    "floralwhite",
    "forestgreen",
    "fuchsia",
    "gainsboro",
    "ghostwhite",
    "gold",
    "goldenrod",
    "gray",
    "green",
    "greenyellow",
    "grey",
    "honeydew",
    "hotpink",
    "indianred",
    "indigo",
    "ivory",
    "khaki",
    "lavender",
    "lavenderblush",
    "lawngreen",
    "lemonchiffon",
    "lightblue",
    "lightcoral",
    "lightcyan",
    "lightgoldenrodyellow",
    "lightgray",
    "lightgreen",
    "lightgrey",
    "lightpink",
    "lightsalmon",
    "lightseagreen",
    "lightskyblue",
    "lightslategray",
    "lightslategrey",
    "lightsteelblue",
    "lightyellow",
    "lime",
    "limegreen",
    "linen",
    "magenta",
    "maroon",
    "mediumaquamarine",
    "mediumblue",
    "mediumorchid",
    "mediumpurple",
    "mediumseagreen",
    "mediumslateblue",
    "mediumspringgreen",
    "mediumturquoise",
    "mediumvioletred",
    "midnightblue",
    "mintcream",
    "mistyrose",
    "moccasin",
    "navajowhite",
    "navy",
    "oldlace",
    "olive",
    "olivedrab",
    "orange",
    "orangered",
    "orchid",
    "palegoldenrod",
    "palegreen",
    "paleturquoise",
    "palevioletred",
    "papayawhip",
    "peachpuff",
    "peru",
    "pink",
    "plum",
    "powderblue",
    "purple",
    "red",
    "rosybrown",
    "royalblue",
    "saddlebrown",
    "salmon",
    "sandybrown",
    "seagreen",
    "seashell",
    "sienna",
    "silver",
    "skyblue",
    "slateblue",
    "slategray",
    "slategrey",
    "snow",
    "springgreen",
    "steelblue",
    "tan",
    "teal",
    "thistle",
    "tomato",
    "turquoise",
    "violet",
    "wheat",
    "white",
    "whitesmoke",
    "yellow",
    "yellowgreen",
  ] as const,
});

export type CommandName = typeof commands.all extends Set<infer X> ? X : never;
